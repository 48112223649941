<template>
    <div id="program">
        <div class="header">
            <Row>
                <Col span="12">
                    <h1 class="title">{{ $t('navigation.programs') }}
                        ({{ total }})
                    </h1>
                </Col>
                <Col span="12" class="clearfix">
                    <Button class="float-right" type="primary" @click="handelAdd">{{ $t('pages.program.add') }}</Button>
                    <Input v-model="query" class="float-right margin-right" clearable :placeholder="$t('pages.program.search')" style="width: auto" @keyup.native.enter="searchNew" @on-clear="searchNew" >
                        <Icon class="pointer" type="ios-search" slot="suffix" @click="searchNew" />
                    </Input>
                </Col>
            </Row>
        </div>
        <div class="main-content">
            <Row class="action-bar">
                <Col span="18">
                    <div v-if="checkAllGroup.length === 0">
                        <RadioGroup class="radio-group-button" v-model="mode" type="button" @on-change="handleMode">
                            <Radio label="all">{{ $t('common.all') }}</Radio>
                            <Radio label="horizontal">{{ $t('pages.screen.horizontal') }}</Radio>
                            <Radio label="vertical">{{ $t('pages.screen.vertical') }}</Radio>
                        </RadioGroup>
                        <Dropdown class="dropdown" trigger="click" placement="bottom-start">
                            <Icon v-if="category" class="close" type="ios-close-circle" @click="clearCategoryList" />
                            <Button type="text">
                                {{ $t('pages.screen.category') }}<span v-if="category">:{{ category.text[lang]?category.text[lang]:category.text._ }}</span>
                                <Icon type="ios-arrow-down"></Icon>
                            </Button>
                            <DropdownMenu slot="list">
                                <DropdownItem v-for="(item, index) in programs_category" :key="index" @click.native="handleCategoryList(item)">{{ item.text[lang]?item.text[lang]:item.text._ }}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown class="dropdown" v-if="is_org" trigger="click" placement="bottom-start" @on-visible-change="handleTreeDropdown">
                            <Icon v-if="nodeList.length" class="close" type="ios-close-circle" @click="clearNodeList" />
                            <Button type="text">
                                {{ $t('navigation.structure') }}
                                <div v-if="nodeList.length" class="tree line-overflow">
                                    <span>:</span>
                                    <span v-for="(item,index) in nodeList" :key="item.department_id">{{ item.title }}
                                        <span v-if="index !== nodeList.length - 1">,</span>
                                    </span>
                                </div>
                                <Icon type="ios-arrow-down"></Icon>
                            </Button>
                            <DropdownMenu slot="list">
                                <Tree :data="data" show-checkbox @on-check-change="handleTree" @on-select-change="handleSelectChange"></Tree>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div v-else>
                        <Button type="primary" class="margin-right" @click="handleRemove(checkAllGroup)">{{ $t('common.del') }}</Button>
                        <Button type="primary" @click="handleCombine(checkAllGroup)">{{ $t('pages.program.merge') }}</Button>
                    </div>
                </Col>
                <Col span="6">
                    <Checkbox
                        v-if="list.length !== 0"
                        class="float-right"
                        :indeterminate="indeterminate"
                        :value="checkAll"
                        @click.prevent.native="handleCheckAll">
                        <span v-if="checkAllGroup.length === 0">{{ $t('common.selectAll') }}</span>
                        <span v-else>{{ $t('common.selected') }}{{ checkAllGroup.length }}{{ $t('pages.program.unit') }}{{ $t(checkAllGroup.length>1?'pages.program.programs':'pages.program.program') }}</span>
                    </Checkbox>
                </Col>
            </Row>
            <no-data v-show="list.length === 0" :text="$t('pages.program.noProgram')"></no-data>
            <div v-show="list.length > 0" class="check-group-wrap">
                <CheckboxGroup class="check-group" v-model="checkAllGroup" @on-change="checkAllGroupChange">
                    <li class="item" v-for="(item, index) in list" :key="item.rowid">
                        <div class="img-wrap">
                            <img class="image-center" :src="`/cym/${item.program.cover_uri}/thumbnail?size=[-1,286]`">
                            <span class="category">{{ item.category_text[lang]?item.category_text[lang]:item.category_text._ }}</span>
                            <template v-if="checkAllGroup.length === 0">
                                <div class="img-mask">
                                </div>
                                <div class="play pointer" @click="handlePaly(item)" >{{ $t('common.post') }}</div>
                                <div class="edit pointer" @click="handelEdit(item.rowid)">{{ $t('common.edit') }}</div>
                                <Dropdown class="more" placement="bottom-start" trigger="click" >
                                    <a href="javascript:void(0)">
                                        <div class="md-more"><Icon class="icon" type="md-more" size="20" /></div>
                                    </a>
                                    <DropdownMenu slot="list" >
                                        <DropdownItem @click.native="handleScreen(item.rowid)">{{ $t('pages.screen.screenDeployed') }}（{{ item.binding_count }}）</DropdownItem>
                                        <DropdownItem @click.native="handleRenameClick(index)">{{ $t('common.rename') }}</DropdownItem>
                                        <DropdownItem @click.native="handleRemove(item)">{{ $t('common.del') }}</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </template>
                            <Checkbox
                                :class="['checkbox', checkAllGroup.length === 0 ? '' : 'checked']"
                                :label="item.rowid"><span>&nbsp;</span></Checkbox>
                        </div>
                        <div class="name-wrap pointer" @click="showElement($event, item.rowid)">
                            <div class="name line-overflow">
                                {{ item.name }}
                            </div>
                            <Icon class="icon" type="md-create" />
                        </div>
                    </li>
                </CheckboxGroup>
                <Spin fix size="large" v-if="loading"></Spin>
                <Page
                    class="page"
                    :total="total"
                    :current="current"
                    :page-size="pageSize"
                    show-elevator
                    @on-change="nextPage" />
            </div>
        </div>
        <playSetting
            :show="playModalShow"
            :materialInfo="materialInfo"
            @confirm="handlePlayConfirm"
            @cancel="handlePlayCancel">
            <div class="material-block" slot="material" :slot-scope="{ materialInfo }">
                <div class="img-wrap">
                    <img class="image-center" :src="`${materialInfo.uri}/thumbnail?size=[295,-1]`">
                </div>
                <div class="info">
                    <p>{{ $t('pages.program.name') }}：{{ materialInfo.name }}</p>
                    <p>{{ $t('pages.space.resolutionRatio') }}：{{ materialInfo.resolution }}</p>
                    <p>{{ $t('pages.program.size2') }}：{{ materialInfo.bytes_size }}</p>
                </div>
            </div>
        </playSetting>
        <add-modal
            :show="addModalShow"
            :department_id="department_id"
            :title="$t('pages.screen.addScreenTip')"
            @cancel="handleAddCancel"></add-modal>
        <screen-modal
            :show="screenModalShow"
            :programRowid="programRowid"
            :is_org="is_org"
            :lang="lang"
            @close="closeScreenModal"></screen-modal>
    </div>  
</template>
<script>
    import { rename, checkbox, dropdownTree } from '@/mixins/index'
    import playSetting from 'views/components/playSetting'
    import noData from '@/views/components/noData'
    import addModal from '@/views/screens/mediaScreens/components/add'
    import screenModal from './components/screenModal'

    export default {
        name: "",
        mixins: [rename, checkbox, dropdownTree],
        components: {
            playSetting,
            noData,
            addModal,
            screenModal
        },
        props: {},
        data () {
            return {
                total: 0,
                current: 1,
                pageSize: 40,
                query: '',
                loading: false,
                list: [],
                indeterminate: false,
                checkAll: false,
                checkAllGroup: [],
                mode: 'all',
                playModalShow: false,
                materialInfo: {},
                data: [],
                nodeList: [],
                treeList: [],
                addModalShow: false,
                category: null,
                screenModalShow: false,
                programRowid: null,
            }
        },
        computed: {
            bucket_token() {
                let bucket_token = ''
                if (this.$store.state.default_bucket_tokens.org) {
                    bucket_token = this.$store.state.default_bucket_tokens.org
                } else {
                    bucket_token = this.$store.state.default_bucket_tokens.user
                }
                return bucket_token
            },
            tree_data() {
                return [this.$store.state.user_tree]
            },
            is_org() {
                return this.$store.state.default_bucket_tokens.org
            },
            department_id() {
                return this.$store.state.user_tree.department_id
            },
            programs_category() {
                return this.$store.state.programs_category
            },
            lang() {
                return this.$store.state.lang
            }
        },
        methods: {
            handleCategoryList(item) {
                this.current = 1
                this.category = item
                this.getData()
            },
            clearCategoryList() {
                this.current = 1
                this.category = null
                this.getData()
            },
            searchNew() {
                this.current = 1
                if (this.loading) return
                this.getData()
            },
            handelAdd() {
                if (this.$store.state.rolesList.length === 0) return this.addModalShow = true
                const { href } = this.$router.resolve({
                    name: "programEdit",
                    query: {
                        status: 'add'
                    }
                });
                window.open(href, '_blank')
            },
            handleAddCancel() {
                this.addModalShow = false
            },
            handelEdit(rowid) {
                const { href } = this.$router.resolve({
                    name: "programEdit",
                    query: {
                        status: 'edit',
                        rowid: rowid
                    }
                });
                window.open(href, '_blank')
            },
            handleScreen(rowid) {
                this.screenModalShow = true
                this.programRowid = rowid
            },
            closeScreenModal() {
                this.screenModalShow = false
                this.getData()
            },
            handleRemove(obj) {
                let list = [],
                    text = this.$t('pages.program.delTip');
                if (obj.constructor === Array) {
                    list = obj
                } else {
                    list.push(obj.rowid)
                    text = `${this.$t('pages.program.deliveredTo')}${obj.binding_count}${this.$t('pages.screen.unit')}${obj.binding_count>1?this.$t('pages.screen.screens'):this.$t('pages.screen.screen')}${this.$t('pages.program.played')}${this.$t('pages.program.delTip')}`
                }

                this.$Modal.confirm({
                    title: this.$t('common.tip'),
                    content: text,
                    okText: this.$t('common.confirm'),
                    cancelText: this.$t('common.cancel'),
                    loading: true,
                    closable: true,
                    onOk: () => {
                        let newList = [...list],
                            len = newList.length,
                            str = ''
                        for (let i = 0; i < len; i++) {
                            str += newList[i]
                            if (i !== len - 1) {
                                str += ','
                            }
                        }
                        this.$api.delete(`programs/${str}`).then(()=>{

                            this.$Message.success(this.$t('message.success'));
                            this.indeterminate = false
                            this.checkAllGroup = []
                            let len = list.length;
                            if(this.total - len === (this.current - 1) * this.pageSize && this.current > 1) {
                                this.current -= 1
                            }
                            this.getData()
                        }).finally(() => {
                            this.$Modal.remove();
                        })
                    }
                });
            },
            handleCombine() {
                this.$Modal.confirm({
                    loading: true,
                    title: this.$t('common.tip'),
                    content: this.$t('pages.program.mergeTip'),
                    onOk: () => {
                        let params = {
                            bucket_token: this.bucket_token,
                            rowid_list: this.checkAllGroup
                        }
                        this.$api.post('programs/merge', params ).then(() => {
                            this.checkAllGroup = []
                            this.getData()
                        }).catch(() => {}).finally(() =>  {
                            this.$Modal.remove()
                        })
                    }
                })
            },
            handleRename(name, rowid) {
                let params = {
                    name: name
                }
                this.$api.put(`programs/${rowid}/name`, params).then(()=>{
                    this.$Message.success(this.$t('message.success'))
                })
            },
            handleRenameClick(index) {
                var eles = document.querySelectorAll('.name')
                eles[index].click()
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                    query: this.query,
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize
                }
                if (!this.is_org) {
                    params.bucket_token = this.bucket_token
                } else {
                    let len = this.nodeList.length
                    if (len) {
                        let bucket_token_list = []
                        this.nodeList.map((item) => {
                            bucket_token_list.push(item.bucket_token)
                        })
                        params.bucket_token_list = JSON.stringify(bucket_token_list)
                    } else {
                        this.treeList = []
                        this.getTreeList(this.tree_data, 'bucket_token')
                        params.bucket_token_list = JSON.stringify(this.treeList)
                    }
                }
                if (this.mode !== 'all') {
                    params.mode = this.mode
                }
                if (this.category) {
                    params.category = this.category.key
                }
                this.loading = true
                this.checkAll = false
                this.indeterminate = false

                this.$api.get('programs/search', { params }).then(({ data, total_count, page })=>{
                    if (page === this.current) {
                        let isAll = true
                        //检测是否当页全选，当前页全勾选，显示全选状态
                        data.map((item)=>{
                            if (this.checkAllGroup.indexOf(item.rowid)== -1) {
                                isAll = false
                            } else {
                                this.indeterminate = true
                            }
                        })

                        if (isAll) {
                            this.indeterminate = false;
                            this.checkAll = true;
                        }

                        this.list = data
                        this.total = total_count
                        this.loading = false
                    }
                }).catch(()=>{
                    this.loading = false
                }).finally(()=>{})
            },
            handleMode() {
                this.current = 1
                this.getData()
            },
            handlePaly(item) {
                let { rowid, name, program } = item,
                    size = item.bytes_size/(1024*1024);
                this.materialInfo = {
                    type: 'program',
                    rowid: rowid,
                    name: name,
                    uri: `/cym/${program.cover_uri}`,
                    resolution: `${program.canvas.width}x${program.canvas.height}PX`,
                    mode: item.mode,
                    category: item.category
                }
                if (size >= 1) {
                    this.materialInfo.bytes_size = Math.round(size*10)/10 + 'M'
                } else if (size >= 0 && size < 1) {
                    this.materialInfo.bytes_size =  Math.round(10*item.bytes_size/1024)/10 + 'KB'
                }
                this.playModalShow = true
            },
            handlePlayConfirm() {
                this.getData()
            },
            handlePlayCancel() {
                this.playModalShow = false
            }

        },
        mounted() {
            this.getData()
            this.data  = this.handleRecursive(this.tree_data)
        }
    }
</script>
<style scoped lang="less">
@import "~less/color.less";

.material-block {
    margin-bottom: 20px;
    height: 166px;
    .img-wrap {
        display: inline-block;
        position: relative;
        margin-right: 20px;
        height: 165px;
        width: 296px;
        background: #cfcfcf;
    }
    .info {
        display: inline-block;
        vertical-align: bottom;
        font-weight: 700;
    }
}
#program {
    .action-bar {
        .radio-group-button {
            position: relative;
            margin-left: -15px;
        }
        .dropdown {
            position: relative;
            margin-left: 20px;
            &::after {
                content: '\200B';
                position: absolute;
                top: 50%;
                left: -10px;
                width: 1px;
                height: 15px;
                background: #b4b9bf;
                transform: translateY(-50%);
            }
            .close {
                display: none;
            }
            &:hover {
                .close {
                    display: block;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                }
            }
            .tree {
                display: inline-block;
                max-width: 100px;
                vertical-align: middle;
            }
        }
        .dropdown {
            position: relative;
            margin-left: 20px;
            &::after {
                content: '\200B';
                position: absolute;
                top: 50%;
                left: -10px;
                width: 1px;
                height: 15px;
                background: #b4b9bf;
                transform: translateY(-50%);
            }
            .close {
                display: none;
            }
            &:hover {
                .close {
                    display: block;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                }
            }
            .tree {
                display: inline-block;
                max-width: 100px;
                vertical-align: middle;
            }
        }
    }
    .main-content {
        .check-group-wrap {
            .check-group {
                width: calc(100% + 8px);
            }
            .item {
                display: inline-block;
                position: relative;
                margin: 0 8px 20px 0;
                width: calc(25% - 8px);
                max-width: 386px;
                .img-wrap {
                    position: relative;
                    padding-top: 56.3%;
                    background: @imge-wrap-background-color;
                    box-shadow: 0px 3px 6px 0px rgba(207,207,207,0.50);
                    &:hover {
                        .checkbox, .play, .more, .edit {
                            display: block;
                        }
                        .img-mask {
                            opacity: 1;
                        }
                    }
                    .category {
                        position: absolute;
                        right: 5%;
                        top: 12%;
                        height: 16px;
                        padding: 0 5px;
                        border-radius: 4px;
                        background: #f8fbff;
                        font-size: 12px;
                        text-align: center;
                        line-height: 16px;
                    }
                    .img-mask {
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0,0,0,.08);
                        transition: opacity .3s ease;
                    }
                    .checkbox, .play, .more, .edit {
                        display: none;
                    }
                    .checkbox {
                        position: absolute;
                        top: 0;
                        left: 0;
                        margin-right: 0;
                        padding: 5px;
                    }
                    .checked {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                    }
                    .more {
                        position: absolute;
                        right: 15px;
                        bottom: 20px;
                        .md-more {
                            position: relative;
                            width: 35px;
                            height: 35px;
                            background: rgba(0,0,0,0.4);
                            border-radius: 50%;
                            &:hover {
                                background: rgba(0,0,0,0.6);
                            }
                            .icon {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                color: #f8fbff;
                            }
                        }
                    }
                    .play {
                        position: absolute;
                        left: 15px;
                        bottom: 20px;
                        width: 100px;
                        height: 35px;
                        background: rgba(0,0,0,0.4);
                        border-radius: 2px;
                        font-size: 14px;
                        line-height: 35px;
                        color: @background-color;
                        text-align: center;
                        &:hover {
                            background: rgba(0,0,0,0.6);
                        }
                    }
                    .edit {
                        position: absolute;
                        left: 125px;
                        bottom: 20px;
                        width: 70px;
                        height: 35px;
                        background: rgba(0,0,0,0.4);
                        border-radius: 2px;
                        font-size: 14px;
                        line-height: 35px;
                        color: @background-color;
                        text-align: center;
                        &:hover {
                            background: rgba(0,0,0,0.6);
                        }
                    }
                    @media screen and (min-width: 1600px) {
                        .more {
                            right: 20px;
                            bottom: 20px;
                            .md-more {
                                width: 40px;
                                height: 40px;
                            }
                        }
                        .play {
                            left: 15px;
                            bottom: 20px;
                            width: 36%;
                            height: 40px;
                            border-radius: 2px;
                            font-size: 16px;
                            line-height: 40px;
                        }
                        .edit {
                            left: 43%;
                            bottom: 20px;
                            width: 70px;
                            height: 40px;
                            font-size: 16px;
                            line-height: 40px;
                        }
                    }
                }
                .name-wrap {
                    margin-top: 5px;
                    .name {
                        display: inline-block;
                        position: relative;
                        max-width: calc(100% - 24px);
                        line-height: 21px;
                        vertical-align: middle;
                    }
                    .icon {
                        display: none;
                        vertical-align: middle;
                    }
                    &:hover {
                        .icon {
                            display: inline-block;
                        }
                    }

                }
            }
        }
    }
}
</style>